<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import OtherEntityNav from '../components/other-entity-nav.vue'
import OtherEntityHeader from '../components/other-entity-header.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import DocumentsList from '../components/documents-list.vue'

const route = useRoute()
const skeleton = ref(true)

onMounted(async () => {
  // // prettier-ignore
  // await Promise.all([
  //   invoiceStore.fetchInvoices('funding-entity', route.params.other_entity_id),
  // ])
  // skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <OtherEntityHeader />
    <OtherEntityNav />
    <DocumentsList investor_type="funding-entity" :investor_id="route.params.other_entity_id" />
  </TheLayout>
</template>
