<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { onMounted, ref } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { RouterLinkBack, VButtonInvisible } from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useRouter } from 'vue-router'
import DocumentForm from '../components/document-form.vue'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

const { t } = useExtendedI18n()
const router = useRouter()
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
workspaceStore.reset()
const skeleton = ref(true)

onMounted(async () => {
  if (!authStore.is_site_or_group_admin) router.push({ name: 'dashboard' })
  await workspaceStore.fetchCurrent()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :fallback="{ name: 'investing.documents' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">New Document</div>
    <DocumentForm investor_type="group" :investor_id="workspaceStore.current.id" v-if="!skeleton" />
  </TheLayout>
</template>
