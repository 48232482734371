<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import OtherEntityNav from '../components/other-entity-nav.vue'
import OtherEntityHeader from '../components/other-entity-header.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useInvoiceStore } from '@/modules/investing/stores/invoice-store'
import InvoicesList from '../components/invoices-list.vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const route = useRoute()

const authStore = useAuthStore()
const invoiceStore = useInvoiceStore()
const current_invoices = computed(() => invoiceStore.invoices)
const isAdmin = computed(() => authStore.is_site_or_group_admin)

const skeleton = ref(true)

onMounted(async () => {
  // prettier-ignore
  await Promise.all([
    invoiceStore.fetchInvoices('funding-entity', route.params.other_entity_id),
  ])
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <OtherEntityHeader />
    <OtherEntityNav />
    <InvoicesList
      :invoices="current_invoices"
      :investable_id="route.params.other_entity_id"
      investable_type="funding-entity"
      :skeleton="skeleton"
      :hidden_columns="['investor']"
      :isAdmin="isAdmin"
    />
  </TheLayout>
</template>
