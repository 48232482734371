<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { useEntityStore } from '../stores/entity-store'
import DocumentsList from '../components/documents-list.vue'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { createOptions } from '@/modules/shared/utils/form'

const route = useRoute()

///////////////////////////////////////////////////////////////////////////////
const skeleton = ref(true)
const entityStore = useEntityStore()
const currentEntity = ref(null)
const investorStore = useInvestorStore()
const investorOptions = computed(() => createOptions(investorStore.state.profiles, { label: 'name' }))

onMounted(async () => {
  await Promise.all([
    entityStore.fetchEntity({
      type: route.params.entity_type as string,
      id: route.params.entity_id as string,
    }),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <DocumentsList
      :investor_type="route.params.entity_type"
      :investor_id="route.params.entity_id"
      :filterOptions="investorOptions"
      :selected_keys="investorStore.state.selectedKeys"
    />
  </TheLayout>
</template>
