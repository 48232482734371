import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '../../auth/stores/auth-store'
import { useTemplateService } from '../services/template-service'
import { CID } from '@/modules/shared/utils/store.types'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'

// Utils

const handle_error = (err: any) => {
  // TODO handle error
  console.error(err)
  return err
}

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Template = {
  id: ID
  cc_roles: any[]
  message: string
  email_subject: string
  file_url: string
  hellosign_template_cache: any
  hellosign_template_id: string
  name: string
  signing_roles: string[]
  templateable_id: string
  templateable_type: string
  title: string
  usage: number
}

type TemplateMap = Map<CID, Template>

export const useTemplateStore = defineStore('template', () => {
  const authStore = useAuthStore()
  const route = useRoute()
  const service = useTemplateService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  /////////////////////////////////////////////////////////////////////////////
  // STATE
  /////////////////////////////////////////////////////////////////////////////

  const item = ref(null)
  const items = ref([])

  /////////////////////////////////////////////////////////////////////////////
  // GETTERS
  /////////////////////////////////////////////////////////////////////////////

  const getters = {}

  /////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  /////////////////////////////////////////////////////////////////////////////

  const actions = {
    generate_embed: async (payload: object) => {
      const [err, data] = await service.generate_embed(null, payload)
      if (err) return handle_error(err)
      return data
    },

    retrieve: async (template_id) => {
      const [err, data] = await service.retrieve(null, { template_id })
      if (err) return handle_error(err)

      item.value = data.data
    },

    // POST

    create: async (payload: object) => {
      const [err, data] = await service.create(null, payload)
      if (err) return handle_error(err)
    },

    duplicateTemplate: async (payload: object) => {
      const [err, data] = await service.duplicateTemplate(null, payload)
      if (err) return handle_error(err)
    },

    replaceTemplate: async (payload: object) => {
      const [err, data] = await service.replaceTemplate(null, payload)
      if (err) return handle_error(err)
    },
  }

  /////////////////////////////////////////////////////////////////////////////
  // UPDATED VERSION
  /////////////////////////////////////////////////////////////////////////////
  const baseUrl = computed(() => `/${route.params.slug}/signing/template`)

  const template_items = ref<TemplateMap>(new Map())
  const templates = computed(() => Array.from(template_items.value.keys()).map((key) => template_items.value.get(key)))

  const fetchTemplates = async (templateable_type, templateable_id) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}s?templateable_type=${templateable_type}&templateable_id=${templateable_id}`,
    )
      .get()
      .json<{ data: Template[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(template_items)
    addItems(
      template_items,
      data.value.data.map((template) => ({ ...template, _cid: template.id })),
    )
  }

  const removeTemplate = async (template_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${template_id}/remove`)
      .delete()
      .json<{ data: Template[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////

  return {
    // state
    item,
    items,
    ...actions,
    ...getters,
    template_items,
    templates,
    fetchTemplates,
    removeTemplate,
  }
})
