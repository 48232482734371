import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'
import { startOfDay } from 'date-fns'

function jsonToFormData(jsonObject) {
  const formData = new FormData()

  Object.keys(jsonObject).forEach((key) => {
    if (jsonObject[key] !== null && jsonObject[key] !== undefined && jsonObject[key] !== '') {
      if (jsonObject[key] instanceof File) {
        formData.append(key, jsonObject[key])
      } else if (typeof jsonObject[key] === 'object' && jsonObject[key] !== null) {
        formData.append(key, JSON.stringify(jsonObject[key]))
      } else {
        formData.append(key, jsonObject[key])
      }
    }
  })

  return formData
}

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type StatusInfo = {
  label: string
  color: string
}

export enum AccreditedStatus {
  Accredited = 'accredited',
  NonAccredited = 'non-accredited',
}

export const accreditatedStatusInfo: Record<AccreditedStatus, StatusInfo> = {
  [AccreditedStatus.Accredited]: { label: 'Accredited Investor', color: 'v-blue' },
  [AccreditedStatus.NonAccredited]: { label: 'Non-Accredited Investor', color: 'gray' },
}

export enum AMLStatus {
  Failed = 'failed',
  Passed = 'passed',
  Pending = 'pending',
  PotentialMatch = 'potential-match',
  Default = 'not started',
}

export const amlStatusInfo: Record<AMLStatus, StatusInfo> = {
  [AMLStatus.Failed]: { label: 'AML Failed', color: 'v-red' },
  [AMLStatus.Passed]: { label: 'AML Passed', color: 'v-green' },
  [AMLStatus.Pending]: { label: 'AML Pending', color: 'v-yellow' },
  [AMLStatus.PotentialMatch]: { label: 'AML Potential Match', color: 'v-yellow' },
  [AMLStatus.Default]: { label: 'AML missing', color: 'v-gray' },
}

export enum ProfileStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export const profileStatusInfo: Record<ProfileStatus, StatusInfo> = {
  [ProfileStatus.Complete]: { label: 'Profile Complete', color: 'v-blue' },
  [ProfileStatus.Incomplete]: { label: 'Profile Incomplete', color: 'v-red' },
}

export enum DisbursementMethod {
  Check = 'check',
  Other = 'other',
  Wire = 'wire',
}

export const disbursementMethodInfo: Record<DisbursementMethod, { label: string }> = {
  [DisbursementMethod.Check]: { label: 'Check' },
  [DisbursementMethod.Other]: { label: 'Other' },
  [DisbursementMethod.Wire]: { label: 'Wire Transfer' },
}

// TODO: move to shared types
enum InvestorType {
  Fund = 'fund',
  GP = 'gp',
  Individual = 'individual',
  SPV = 'spv',
}

// TODO: move to shared types
export type Investor = {
  _cid: CID
  _custom_type: InvestorType
  _key: string
  id: string
  initials: string
  name: string
  legal_name: string
  email: string
  status: string
  aml: {
    identity_log_id: boolean
    is_whitelisted: boolean
    match_status: string
    share_url: string
    updated_at: string
  }

  accredited_status: AccreditedStatus
  aml_status: AMLStatus
  profile_status: ProfileStatus
  registration_token: {
    token: string
    sent_at: string
  }
}

type ID = number | string

export type Investment = {
  id: ID
  accrued_interest: Money
  company: {
    id: ID
    name: string
  }
  current_value: Money
  disbursement: Money
  industry: {
    id: ID
    name: string
  }
  initial_value: Money
  investor: {
    _cid: CID
    id: ID
    name: string
  }
  method_label: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type IndividualMap = Map<CID, Investor>
type InvestorMap = Map<CID, Investor>
type StatMap = Map<CID, { [index: number]: any }>
type ProfileMap = Map<CID, Object>

function mapToObject(map: Map<any, any>): any {
  let obj: any = {}
  map.forEach((value, key) => {
    obj[key] = value
  })
  return obj
}

export const useInvestingInvestorStore = defineStore('investing/investorStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  // INDIVIDUALS
  const items = ref<IndividualMap>(new Map())
  const itemsArray = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))
  const individuals = computed(() => itemsArray.value)

  // INVESTORS (spvs, funds, gps, individuals, other-entities)
  const investorItems = ref<InvestorMap>(new Map())
  const investorItemsArray = computed(() =>
    Array.from(investorItems.value.keys()).map((key) => investorItems.value.get(key)),
  )
  const investors = computed(() => investorItemsArray.value)

  // STATS
  const statItems = ref({})
  const statsArray = computed(() => Object.values(statItems.value))
  const stats = computed(() => statsArray.value)

  // PROFILES
  const profileItems = ref<ProfileMap>(new Map())
  const profileItemsArray = computed(() =>
    Array.from(profileItems.value.keys()).map((key) => profileItems.value.get(key)),
  )
  const profiles = computed(() => mapToObject(profileItems.value))
  const selectedProfileKeys = ref([])

  const addIndividual = async (payload, cid: string) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individual/add`).post(payload).json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Individual failed to add')
      return
    }

    await fetchIndividuals(cid)
    notificationStore.enqueue('success', 'Individual was successfully added')
  }

  const fetchIndividuals = async (cid: string) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individuals?cid=${cid}`).get().json<{ data: Investor[] }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Individuals failed to load')
      return
    }

    clearItems(items)
    addItems(items, data.value.data)
  }

  const fetchIndividual = async (individual_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individual/${individual_id}`)
      .get()
      .json<{ data: Investor }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Individual failed to load')
      return
    }

    addItem(items, data.value.data)
  }

  const importIndividuals = async (csv_file: File) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individuals/import`)
      .post(jsonToFormData({ csv_file }))
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
    }

    Object.keys(data.value.data).forEach((key) => {
      if (data.value.data[key]) notificationStore.enqueue(key, data.value.data[key])
    })
  }

  const sendRegistrationInvitationToIndividual = async (individual_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individual/${individual_id}/send_registration_invitation`)
      .post({})
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
    }
  }

  const fetchInvestors = async () => {
    const { data, error } = await useFetch(`${baseUrl.value}/investors`).get().json<{ data: Investor[] }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Investors failed to load')
      return
    }

    clearItems(investorItems)
    addItems(investorItems, data.value.data)
  }

  const fetchStats = async (cid: CID) => {
    const { data, error } = await useFetch(`${baseUrl.value}/stats?cid=${cid}`).get().json<{ data: any[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Stats failed to load')
      return
    }

    const today = startOfDay(new Date()).getTime()
    const items = data.value.data.reduce((acc, cur) => {
      return {
        ...acc,
        [cur._cid]: cur,
      }
    }, {})

    statItems.value = { ...items }
  }

  const fetchProfiles = async (cid?: string) => {
    const { data, error } = await useFetch(`${baseUrl.value}/investors/profiles?cid=${cid || 'all'}`)
      .get()
      .json<{ data: Object[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Profiles failed to load')
      return
    }

    clearItems(profileItems)
    addItems(profileItems, data.value.data)
  }

  const removeIndividual = async (individual_id: string) => {
    await useFetch(`${baseUrl.value}/individual/${individual_id}/remove`).delete().json<{}>()
    notificationStore.enqueue('success', 'Individual was successfully deleted')
  }

  const revokeAccessIndividual = async (individual_id: string, cid: string) => {
    await useFetch(`${baseUrl.value}/individual/${individual_id}/revoke`).put().json<{}>()
    await fetchIndividuals(cid)
    notificationStore.enqueue('success', 'Individual was successfully revoked')
  }

  const activateAccessIndividual = async (individual_id: string, cid: string) => {
    await useFetch(`${baseUrl.value}/individual/${individual_id}/activate`).put().json<{}>()
    await fetchIndividuals(cid)
    notificationStore.enqueue('success', 'Individual was successfully activated')
  }

  const updateIndividual = async (payload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individual/${payload.id}`)
      .put(jsonToFormData(payload))
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Update individual failed')
    }
  }

  const updateIndividualAML = async (payload) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/individual/${payload.individual_id}/aml/${payload.identity_log_id}/update`,
    )
      .put(payload)
      .json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Update individual aml failed')
      return
    }

    await fetchIndividual(payload.individual_id)
  }

  const updateIndividualProfileImage = async (individual_id, image_url) => {
    const { data, error } = await useFetch(`${baseUrl.value}/individual/${individual_id}/update_profile_image`)
      .post({ image_url })
      .json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Update profile image failed')
      return
    }

    await fetchIndividual(individual_id)
    notificationStore.enqueue('success', 'Profile image updated successfully')
  }

  return {
    items,
    individuals,
    investors,
    investorItems,
    stats,
    profiles,
    selectedProfileKeys,

    addIndividual,
    fetchIndividuals,
    fetchIndividual,
    fetchInvestors,
    fetchStats,
    fetchProfiles,
    importIndividuals,
    removeIndividual,
    revokeAccessIndividual,
    sendRegistrationInvitationToIndividual,
    activateAccessIndividual,
    updateIndividual,
    updateIndividualAML,
    updateIndividualProfileImage,
  }
})
