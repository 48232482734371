import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

export type Investment = {
  id: ID
  accrued_interest: Money
  current_price: Money
  current_shares: number
  current_value: Money
  initial_price: Money
  initial_shares: number
  initial_value: Money
  disbursement: Money
  expiration_date: string

  method_label: string
  minimum_annual_return: Money
  investment_type: string
  investor_cid: string
  total_shares: number
  valuation_cap: Money

  company: {
    id: ID
    name: string
    logo_url: string
  }
  industry: {
    id: ID
    name: string
  }
  investor: {
    _cid: CID
    id: ID
    name: string
  }
  term_sheet: {
    id: ID
    name: string
    terms: any[]
  }
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

export const usePortfolioStore = defineStore('investing/portfolioStore', () => {
  const notificationStore = useNotificationStore()
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref([])
  const investments = computed(() => items.value)

  const fetchInvestments = async (cid: string, params: any) => {
    reset()
    const [resource, id] = cid.split(':')
    const { data, error } = await useFetch(`${baseUrl.value}/${resource}/${id}/investments?as_of=${params.as_of}`)
      .get()
      .json<{ data: Investment[] }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Investments failed to load')
      return
    }

    items.value = data.value.data
  }

  const fetchPortfolio = async (payload: { _cid: string; as_of_date: Date }) => {
    reset()
    const { data, error } = await useFetch(
      `${baseUrl.value}/portfolio?cid=${payload._cid}&as_of_date=${payload.as_of_date}`,
    )
      .get()
      .json<{ data: Investment[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Investments failed to load')
      return
    }

    items.value = data.value.data
  }

  const reset = () => {
    items.value = []
  }

  return {
    items,
    investments,

    reset,
    fetchInvestments,
    fetchPortfolio,
  }
})
