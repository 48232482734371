<script setup lang="ts">
import { capitalize } from 'lodash'
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { VButton, VSection, VButtonInvisible } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useInvoiceStore } from '../stores/invoice-store'
import InvoicesList from '../components/invoices-list.vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const { t } = useI18n()
const skeleton = ref(true)
const authStore = useAuthStore()
const invoiceStore = useInvoiceStore()
const current_invoices = computed(() => invoiceStore.invoices)
const isAdmin = computed(() => authStore.is_site_or_group_admin)

onMounted(async () => {
  // prettier-ignore
  await Promise.all([
    invoiceStore.fetchInvoices('group', 0), // 0 is set because it is needed by the API. Ideally this should come from the workspace current data
  ])
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <a class="text-lg" :href="`${rails_url()}/group/settings`">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </a>
      </div>
    </div>

    <VSection :label="capitalize(t('shared.invoice', 0))">
      <template #actions>
        <a class="text-lg" :href="`${rails_url()}/invoices/new`">
          <VButton size="md" variant="v-blue">
            {{ capitalize(t('shared.send new invoice')) }}
          </VButton>
        </a>
      </template>
      <InvoicesList
        :invoices="current_invoices"
        investable_id="0"
        investable_type="group"
        :skeleton="skeleton"
        :enable_multiple_payment="true"
        :isAdmin="isAdmin"
      />
    </VSection>
  </TheLayout>
</template>
