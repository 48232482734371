import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Invoice = {
  _cid: CID
  _key: string
  id: string
  amount: Money
  date: string
  investor: {
    _cid: CID
    _custom_type: string
    id: ID
    name: string
  }
  is_call_invoice: boolean
  marked_as_paid: boolean
  name: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type InvoiceMap = Map<CID, Invoice>

export const useInvoiceStore = defineStore('investing/invoiceStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<InvoiceMap>(new Map())
  const invoices = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchInvoices = async (investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/invoices`)
      .get()
      .json<{ data: Invoice[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(items, data.value.data)
  }

  const markMultipleInvoicesAsPaid = async (payload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/invoices/mark_multiple_as_paid`).post(payload).json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const markMultipleInvoicesAsUnpaid = async (invoice_ids) => {
    const { data, error } = await useFetch(`${baseUrl.value}/invoices/mark_multiple_as_unpaid`)
      .post({ invoice_ids })
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  return {
    items,
    invoices,

    fetchInvoices,
    markMultipleInvoicesAsPaid,
    markMultipleInvoicesAsUnpaid,
  }
})
