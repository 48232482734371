<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { capitalize } from 'lodash'
import DocumentsList from '../components/documents-list.vue'
import { useI18n } from 'vue-i18n'
import { VButtonInvisible, VSection } from '@/modules/shared/components'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import { onMounted, ref } from 'vue'
import { rails_url } from '@/modules/shared/utils/rails'

const { t } = useI18n()
const workspaceStore = useWorkspaceStore()
workspaceStore.reset()
const skeleton = ref(true)

onMounted(async () => {
  await workspaceStore.fetchCurrent()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <a class="text-lg" :href="`${rails_url()}/group/settings`">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </a>
      </div>
    </div>
    <VSection label="Documents">
      <DocumentsList
        investor_type="group"
        :investor_id="workspaceStore.current.id"
        class="mt-10"
        :skeleton="skeleton"
      />
    </VSection>
  </TheLayout>
</template>
