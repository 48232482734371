<script setup lang="ts">
import { onClickOutside, useConfirmDialog, useFocus, useMagicKeys } from '@vueuse/core'
import { computed, ref, watch, nextTick } from 'vue'
import { VIcon } from '@/modules/shared/components'
import { useSearch } from '@/modules/shared/composables/use-search'
import { search } from '@/modules/shared/utils/deep-search'

// NOTE: this is duplicated in multiple places
// TODO: move to shared types
type Option = {
  disabled?: boolean
  label: string
  value: string
  section_label?: boolean
  type?: string
}

const props = withDefaults(
  defineProps<{
    aligned?: string
    hideDisabled?: boolean
    modelValue: string[]
    options: Option[]
  }>(),
  {
    aligned: 'left',
    hideDisabled: false,
  },
)
const selected_options = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

const { isRevealed, reveal, cancel } = useConfirmDialog()

function isSelected(value: string) {
  return props.modelValue.includes(value)
}

const selectOption = (option) => {
  if (option.section_label) return
  const value = option.value
  let newValue

  if (!isSelected(value)) {
    newValue = [...props.modelValue, value]
    selected_options.value = newValue
    emit('update:modelValue', newValue)
  } else {
    deselectOption(value)
  }
}

const deselectOption = (value: string) => {
  let newValue
  newValue = props.modelValue.filter((val) => val !== value)
  selected_options.value = newValue
  emit('update:modelValue', newValue)
}

const target = ref(null)

onClickOutside(target, (event) => cancel())

const { escape } = useMagicKeys()

///////////////////////////////////////////////////////////////////////////////
// Search
///////////////////////////////////////////////////////////////////////////////
const autofocus = ref()
const { focused } = useFocus(autofocus)
const { clearQuery, query } = useSearch()
const preparedOptions = computed(() => {
  if (!query.value) return props.options
  return search(
    props.options.filter((option) => !option.section_label),
    query.value,
  )
})

const openDropdown = () => {
  reveal()
  focused.value = true
  nextTick(() => autofocus.value.focus())
}

watch(
  () => props.modelValue,
  () => clearQuery(),
)

watch(escape, (v) => {
  if (v) {
    cancel()
  }
})
</script>

<template>
  <!-- this is design for document form -->
  <div class="relative z-50" :class="aligned === 'left' ? 'text-left' : 'text-right'" ref="target">
    <ul v-if="selected_options.length" class="mb-5 flex flex-wrap items-start gap-2">
      <li
        v-for="option in options"
        :key="option.label"
        v-show="isSelected(option.value)"
        class="relative w-full list-inside list-disc border-b-[1px] py-2 text-sm"
      >
        {{ option.label }}
        <VIcon
          name="x"
          @click="deselectOption(option.value)"
          class="absolute -left-7 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer"
        />
      </li>
    </ul>
    <div @click="isRevealed ? cancel() : openDropdown()">
      <slot name="default"></slot>
    </div>
    <ul
      v-show="isRevealed"
      class="absolute z-50 mt-1 max-h-[480px] w-full min-w-[220px] divide-y-0 divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-[1px] ring-gray-700/20"
      :class="aligned === 'left' ? 'left-0' : 'right-0'"
    >
      <li>
        <div class="relative flex-grow">
          <input
            type="text"
            class="relative z-40 w-full rounded-none rounded-tl-md border-b border-l-0 border-r-0 border-t-0 border-gray-200 bg-transparent text-sm focus:border-gray-200 focus:ring focus:ring-sky-200 focus:ring-opacity-0"
            :placeholder="`Search by anything`"
            v-model="query"
            ref="autofocus"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 z-50 flex items-center pr-3">
            <button
              @click="clearQuery"
              class="curosr-pointer pointer-events-auto inline-block rounded-md bg-gray-100 p-1"
              v-show="query"
            >
              <VIcon name="x" class="h-3 w-3 text-gray-400" />
            </button>
          </div>
        </div>
      </li>
      <li
        v-for="option in preparedOptions"
        v-show="!(option.disabled && hideDisabled)"
        :key="option.label"
        class="relative flex items-center space-x-2 whitespace-nowrap px-3 py-1.5 pr-6 text-sm text-gray-700 hover:bg-gray-100"
        @click="() => selectOption(option)"
        :class="[option.section_label ? 'bg-gray-100' : 'cursor-pointer']"
      >
        <VIcon name="check" :class="['h-4 w-4 text-[#3b88af]', isSelected(option.value) ? '' : 'invisible']" />
        <div class="font-medium">
          {{ option.label }}
        </div>
        <div class="flex-grow text-right text-xs">{{ option.type }}</div>
      </li>
    </ul>
  </div>
</template>
