<script setup lang="ts">
import { ref } from 'vue'
import VCurrencyField from '@/modules/shared/components/v-currency-field.vue'
import { VTextField } from '@/modules/shared/components'

const money = ref(null)
const money2 = ref(null)
</script>

<template>
  <div class="mx-auto mt-40 w-1/2 bg-black p-5">
    <VCurrencyField v-model="money" />
  </div>
  <div class="mx-auto mt-40 w-1/2 bg-black p-5">
    <VTextField type="currency" v-model="money2" />
  </div>
</template>
