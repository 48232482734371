<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { get, capitalize } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { VButton, VIcon, VTable, VDropdownImproved, VSection } from '@/modules/shared/components'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useDocumentStore } from '@/modules/tax/stores/document-store'

const props = withDefaults(
  defineProps<{
    modelValue: any
    label?: string
    cid?: string
    investor_filter_enabled?: boolean
    skeleton?: boolean
    filter_options: any
    customFilter?: Function
  }>(),
  {
    cid: 'group',
    investor_filter_enabled: true,
    skeleton: false,
    customFilter: null,
  },
)

const emit = defineEmits(['update:modelValue'])

const route = useRoute()
const { t } = useExtendedI18n()
const internal_skeleton = ref(true)
const skeleton = computed(() => props.skeleton || internal_skeleton.value)
const authStore = useAuthStore()
const documentStore = useDocumentStore()
const filter = ref({
  selectedProfileKeys: [],
  selectedYears: [],
})
const selectedYearsString = computed(() =>
  filter.value.selectedYears.length > 0 ? filter.value.selectedYears.join(', ') : '(not selected)',
)
const default_year = new Date().getFullYear() - 1

const profileOptions = computed(() => props.filter_options)
const viewingAs = computed(() => {
  if (filter.value.selectedProfileKeys.length === profileOptions.value.length) {
    return `all ${t('shared.investor', 0)}`
  }

  if (filter.value.selectedProfileKeys.length === 1) {
    return profileOptions.value.find((option) => option.value === filter.value.selectedProfileKeys[0])?.name
  }

  if (filter.value.selectedProfileKeys.length > 1 || filter.value.selectedProfileKeys.length < 1) {
    return `${filter.value.selectedProfileKeys.length} ${t('shared.investor', filter.value.selectedProfileKeys.length)}`
  }
})

const can_access_entities = computed(() => {
  if (route.name === 'investing.individual.taxes') {
    return (
      route.params.individual_id === authStore.current_user.investor_id?.toString() || authStore.is_site_or_group_admin
    )
  }
  return true
})

const taxYearOptions = computed(() => {
  const years = [{ label: default_year, value: default_year }]
  const yearsAdded = [default_year]
  documentStore.documents.forEach((document) => {
    if (!yearsAdded.includes(document.tax_year)) {
      years.push({ label: document.tax_year, value: document.tax_year })
      yearsAdded.push(document.tax_year)
    }
  })

  return years
})

const filteredDocuments = computed(() =>
  documentStore.documents
    .filter((document) => filter.value.selectedProfileKeys.includes(document.investor._cid))
    .filter((document) => filter.value.selectedYears.includes(document.tax_year)),
)

const isSiteAdmin = computed(() => authStore.is_site_admin)

const setFilter = () => (filter.value.selectedProfileKeys = profileOptions.value.map((option) => option.value))

onMounted(async () => {
  emit('update:modelValue', {
    setFilter: setFilter,
    current_filter: filter,
  })
  await Promise.all([documentStore.fetchDocuments(props.cid)])

  filter.value.selectedYears = [default_year] // TODO this should be a query param
  internal_skeleton.value = false
})
</script>

<template>
  <VSection :label="label">
    <div class="z-50 flex items-center justify-between">
      <div class="mb-6 flex items-center space-x-2">
        <VDropdownImproved v-model="filter.selectedYears" aligned="left" :options="taxYearOptions">
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Filter by year {{ selectedYearsString }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
        <VDropdownImproved
          v-model="filter.selectedProfileKeys"
          aligned="left"
          :options="profileOptions"
          v-if="investor_filter_enabled"
        >
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Filter by {{ viewingAs }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
      </div>
    </div>

    <VTable
      :columns="[
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'fileable.name',
          name: capitalize(t('shared.entity')),
          type: 'string',
          align: 'left',
          is_visible: true,
          sorted: true,
        },
        {
          key: 'investor',
          name: capitalize(t('shared.investor')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'attachment_download_file_name',
          name: capitalize(t('shared.file name')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'updated_at',
          name: capitalize(t('shared.updated at')),
          type: 'date',
          align: 'center',
          is_visible: true,
        },
        {
          key: 'tax_year',
          name: capitalize(t('shared.year')),
          type: 'string',
          align: 'center',
          is_visible: true,
        },
      ]"
      :items="filteredDocuments"
      :name="`${route.params.slug}-documents`"
      :skeleton="skeleton"
      :slots="['fileable.name', 'attachment_download_file_name', 'actions']"
    >
      <template #fileable.name="{ item }">
        <div class="mb-1 flex w-72 items-center justify-between">
          <div>
            <RouterLink
              class="hyperlink"
              :to="{
                name: 'investing.entity-overview',
                params: { entity_id: get(item, 'fileable.id'), entity_type: get(item, 'fileable.type') },
              }"
              v-if="can_access_entities"
            >
              {{ get(item, 'fileable.name') }}
            </RouterLink>
            <template v-else>
              {{ get(item, 'fileable.name') }}
            </template>
          </div>
        </div>
      </template>
      <template #investor="{ item }">
        <p class="max-w-lg overflow-hidden text-ellipsis" :title="item.name">
          {{ unflattenKey(item, 'investor').name }}
        </p>
      </template>
      <template #attachment_download_file_name="{ item }">
        <p class="max-w-xl overflow-hidden text-ellipsis" :title="item.attachment_download_file_name">
          {{ item.attachment_download_file_name }}
        </p>
      </template>
      <template #actions="{ item }">
        <div class="flex space-x-1.5">
          <a :href="item.attachment_download_url" target="_blank">
            <VButton size="xs">
              {{ capitalize(t('shared.download')) }}
            </VButton>
          </a>
          <a :href="item.attachment_view_url" target="_blank">
            <VButton size="xs">
              {{ capitalize(t('shared.view')) }}
            </VButton>
          </a>
          <VButton v-if="isSiteAdmin" size="xs" :click="() => documentStore.destroy(item.uuid)">
            {{ capitalize(t('shared.delete')) }}
          </VButton>
        </div>
      </template>
    </VTable>
  </VSection>
</template>
