import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Document = {
  id: ID
  date: string
  name: string
  signatures: {
    id: ID
    date: string
    investor: {
      _cid: CID
      id: ID
      name: string
    }
    name: string
    signer: {
      _cid: CID
      id: ID
      name: string
    }
    status: string
    status_code: string
  }[]
  status: string
  voided_at: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type DocumentMap = Map<CID, Document>

export const useDocumentStore = defineStore('investing/documentStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)
  const notificationStore = useNotificationStore()

  const items = ref<DocumentMap>(new Map())
  const documents = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchDocuments = async (investor_type, investor_id) => {
    clearItems(items)
    const { data, error } = await useFetch(`${baseUrl.value}/${investor_type}/${investor_id}/documents`)
      .get()
      .json<{ data: Document[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
    addItems(
      items,
      data.value.data.map((document) => ({ ...document, _cid: document.id })),
    )
  }

  const voidDocument = async (document_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/document/${document_id}/void`).post().json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const signatureSendReminder = async (signature_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/document/signature/${signature_id}/send_reminder`)
      .post()
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const addDocument = async (document) => {
    const { data, error } = await useFetch(`${baseUrl.value}/document/add`).post({ document }).json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const downloadDocument = async (document_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/document/${document_id}/download`)
      .post()
      .json<{ data: string }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', data.value.data)
      return
    }
    if (data.value.data) window.open(data.value.data, '_blank')
  }

  return {
    items,
    documents,

    fetchDocuments,
    voidDocument,
    signatureSendReminder,
    addDocument,
    downloadDocument,
  }
})
