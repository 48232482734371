<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import DocumentsList from '../components/documents-list.vue'

const route = useRoute()
const skeleton = ref(true)

onMounted(async () => {
  // // prettier-ignore
  // await Promise.all([
  //   invoiceStore.fetchInvoices('individual', route.params.individual_id),
  // ])
  // skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />
    <DocumentsList investor_type="individual" :investor_id="route.params.individual_id" />
  </TheLayout>
</template>
