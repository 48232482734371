<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useInvoiceStore } from '@/modules/investing/stores/invoice-store'
import InvoicesList from '../components/invoices-list.vue'
import { useAuthStore } from '../../auth/stores/auth-store'

const route = useRoute()

const authStore = useAuthStore()
const invoiceStore = useInvoiceStore()
const current_invoices = computed(() => invoiceStore.invoices)
const isAdmin = computed(() => authStore.is_site_or_group_admin)

const skeleton = ref(true)

onMounted(async () => {
  // prettier-ignore
  await Promise.all([
    invoiceStore.fetchInvoices('individual', route.params.individual_id),
  ])
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />
    <InvoicesList
      :invoices="current_invoices"
      :investable_id="route.params.individual_id"
      investable_type="individual"
      :skeleton="skeleton"
      :isAdmin="isAdmin"
    />
  </TheLayout>
</template>
