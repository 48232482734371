import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type OtherEntity = {
  _cid: CID
  _key: string
  id: string
  admins: {
    _cid: CID
    id: string
    name: string
  }[]
  name: string
  date: string
  type: string
  tax_id: string
  input_capital: boolean
  management_fee_percentage: number
  number_of_investors: number
  carried_interest: number
  street1: string
  street2: string
  city: string
  zip: string
  country: string
  disbursement_method: string
  disbursement_wire_bank_name: string
  disbursement_wire_bank_address: string
  disbursement_wire_bank_routing_number: string
  disbursement_wire_bank_account_number: string
  disbursement_wire_bank_swift_code: string
  disbursement_wire_account_name: string
  disbursement_wire_for_further_credit_to: string
  disbursement_check_receiver_name: string
  disbursement_other_details: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type OtherEntityMap = Map<CID, OtherEntity>

export const useOtherEntityStore = defineStore('investing/otherEntityStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)
  const notificationStore = useNotificationStore()

  const items = ref<OtherEntityMap>(new Map())
  const other_entities = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const addOtherEntity = async (otherEntity: OtherEntity) => {
    let url = `${baseUrl.value}/other-entity/add`

    const { data, error } = await useFetch(url).post({ otherEntity }).json<{ data: OtherEntity }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    notificationStore.enqueue('success', 'Associated entity was successfully added')
    addItem(items, data.value.data)
    return data.value.data
  }

  const fetchOtherEntities = async (investable_type?, investable_id?) => {
    clearItems(items)
    let url = `${baseUrl.value}/other-entities`

    if (investable_type && investable_id) url = `${baseUrl.value}/${investable_type}/${investable_id}/other-entities`
    const { data, error } = await useFetch(url).get().json<{ data: OtherEntity[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    addItems(items, data.value.data)
  }

  const fetchOtherEntity = async (id: string) => {
    let url = `${baseUrl.value}/other-entity/${id}`

    const { data, error } = await useFetch(url).get().json<{ data: OtherEntity }>()

    if (error.value) {
      notificationStore.enqueue('error', 'Associated entity failed to load')
      return
    }

    addItem(items, data.value.data)
  }

  const removeOtherEntity = async (other_entity_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/other-entity/${other_entity_id}/remove`)
      .delete()
      .json<{}>()

    if (error.value) {
      console.log(error.value)
      notificationStore.enqueue('error', 'Associated entity failed to remove')
      return
    }
    notificationStore.enqueue('success', 'Associated entity successfully to remove')
  }

  const updateOtherEntity = async (payload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/other-entity/${payload.funding_entity.id}/update`)
      .post({ otherEntity: payload })
      .json<{}>()

    if (error.value) {
      console.log(error.value)
      notificationStore.enqueue('error', 'Associated entity failed to update')
      return
    }
    notificationStore.enqueue('success', 'Associated entity successfully to update')
  }

  return {
    items,
    other_entities,

    addOtherEntity,
    fetchOtherEntities,
    fetchOtherEntity,
    removeOtherEntity,
    updateOtherEntity,
  }
})
