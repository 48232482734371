<script setup lang="ts">
import { capitalize, isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { RouterLinkBack, VButtonInvisible, VSection, VTable } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useEntityStore } from '../stores/entity-store'
import { unflattenKey } from '@/modules/shared/utils/v-table'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const formatEntityType = (type: string) => {
  const entity_types = {
    fund: 'Fund',
    spv: 'SPV',
    gp: 'GP',
  }

  return entity_types[type]
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { t } = useExtendedI18n()

const entityStore = useEntityStore()

const entities = computed(() => entityStore.entities)

const skeleton = ref(true)

const table = ref(null);

const filter = ref("all");


function pending_spv_filter(event) {
  table.value.query = "-subscription_status:active,total_capital_committed:=0,entity.ein:\"\",-invoice_items_total:invoice_items_paid,-documents_complete:documents_total"
  table.value.selectedColumnKeys = ["name", "total_capital_committed", "subscription_status", "tax_id","is_blue_sky_complete","document_status","total_called","invoice_status" ]
  filter.value = "pending_spv"
}

function all_filter(event) {
  table.value.query = ""
  table.value.selectedColumnKeys = ["name", "group.name", "entity_type", "date_established","created_at","selected_subscriptions","tax_id","tax_statuses.federal_extension_status","tax_statuses.state_extension_status","tax_statuses.federal_filing_status","tax_statuses.return_status","tax_statuses.k1_distribution_status","document_status"]
  filter.value = "all"
}

onMounted(async () => {
  await entityStore.fetchEntities() 
  skeleton.value = false
})

</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6">
      <RouterLinkBack :to="`${rails_url()}/investors`" class="text-lg">
        <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
      </RouterLinkBack>
    </div>
    <VSection label="Entities">
      <div class="mb-5 mt-5 text-xs">
        <button @click="all_filter"><span :class="`${filter === 'all' ? 'font-bold' : ''}`">No filters</span></button><br />
        <button @click="pending_spv_filter"><span :class="`${filter === 'pending_spv' ? 'font-bold' : ''}`">Pending SPVs</span></button>
      </div>
      <VTable
        ref="table"
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
            fixed: true,
          },
          {
            key: 'legal_name',
            name: capitalize(t('shared.legal name')),
            type: 'string',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'group.name',
            name: capitalize(t('shared.group')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'group.subdomain',
            name: capitalize(t('shared.subdomain')),
            type: 'string',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'entity_type',
            name: capitalize(t('shared.type')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'date_established',
            name: capitalize(t('shared.date established')),
            type: 'date',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'created_at',
            name: capitalize(t('shared.date added')),
            type: 'date',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'selected_subscriptions',
            csv_key: 'selected_services',
            name: capitalize(t('shared.subscription')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'tax_id',
            name: capitalize(t('shared.EIN')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_statuses.federal_extension_status',
            name: capitalize(t('shared.federal extension status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_statuses.state_extension_status',
            name: capitalize(t('shared.state extension status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_statuses.federal_filing_status',
            name: capitalize(t('shared.federal filing status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_statuses.state_filing_status',
            name: capitalize(t('shared.state filing status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_statuses.return_status',
            name: capitalize(t('shared.return status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },

          {
            key: 'tax_statuses.k1_distribution_status',
            name: capitalize(t('shared.K-1 distribution status')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'total_capital_committed',
            name: capitalize(t('shared.total capital committed')),
            type: 'number',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'document_status',
            name: capitalize(t('shared.document status')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'invoice_status',
            name: capitalize(t('shared.invoice status')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'subscription_status',
            name: capitalize(t('shared.subscription status')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'total_called',
            name: capitalize(t('shared.total called')),
            type: 'number',
            align: 'left',
            is_visible: false,
          }
        ]"
        :items="entities"
        name="all-entities"
        :skeleton="skeleton"
        :slots="['name', 'entity_type', 'group.name', 'selected_subscriptions']"
      >
        <template #name="{ item }">
          <div class="mb-1 flex items-center justify-between">
            <div>
              <RouterLink
                class="hyperlink"
                :to="{
                  name: 'investing.entity-overview',
                  params: { entity_id: item.id, entity_type: item.entity_type, slug: item['group.subdomain'] },
                }"
              >
                {{ item.name }}
              </RouterLink>
            </div>
            <div class="text-right">
              <RouterLink
                class="hyperlink"
                :to="{
                  name: 'tax.entities',
                  params: { slug: item['group.subdomain'] },
                }"
                >Taxes</RouterLink
              >
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-start space-x-2">
            <div class="whitespace-normal text-sm text-gray-600" v-if="!isEmpty(item.legal_name)">
              {{ item.legal_name }}
            </div>
          </div>
        </template>
        <template #group.name="{ item }">
          <div>
            {{ item['group.name'] }}
          </div>
          <div>&lt;{{ item['group.subdomain'] }}&gt;</div></template
        >
        <template #entity_type="{ item }">
          {{ formatEntityType(item.entity_type) }}
        </template>
        <template #selected_subscriptions="{ item }">
          <div class="flex flex-col px-3 text-left text-sm text-gray-600">
            <a
              v-for="subscription in unflattenKey(item, 'selected_subscriptions')"
              class="mb-1 inline-block w-fit whitespace-nowrap rounded bg-white px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-gray-300"
              :href="`/${item['group.subdomain']}/billing/${item.entity_type}/${item.id}`"
            >
              <span class="pr-1.5">{{ subscription.name }}</span>
              <span class="border-l border-gray-300 pl-1.5">{{ subscription.status }}</span>
            </a>
          </div>
        </template>
      </VTable>
    </VSection>
  </TheLayout>
</template>
