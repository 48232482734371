<script setup lang="ts">
import { useFocus } from '@vueuse/core'
import { computed, ref } from 'vue'
import { currencies, Money } from '../utils/money'
import { toNumber, toMoney } from '@/modules/shared/utils/money'
import { format, parse } from '@/modules/shared/utils/v-table'

const props = withDefaults(
  defineProps<{
    modelValue?: Money
    description?: string
    disabled?: boolean
    error?: string
    id?: string
    inline?: boolean
    inputClass?: string
    label?: string
    name?: string
    onChange?: any
    placeholder?: string
    property?: string
    required?: boolean
    v$?: any
    size?: string
    align?: string
  }>(),
  {
    disabled: false,
    inline: false,
    placeholder: '',
    v$: {},
    onChange: () => {},
    size: 'md',
    align: 'right',
  },
)

const inputRef = ref(null)
const { focused: isFocused } = useFocus(inputRef)
const emit = defineEmits(['update:modelValue'])
const format_currency = (value) => format(value, 'currency')
const unformat_currency = (value) => {
  if (!value && value !== 0) return null
  return parse(value, 'currency')
}

const canSet = (value) => {
  const stringNumber = value.toString().replace(/[^0-9.-]+/g, '')
  if (stringNumber === '.') return false
  const decimalNumbers = stringNumber.split('.')[1]
  if (stringNumber.split('.').length > 1 && decimalNumbers.length < 2) {
    if (decimalNumbers === '') return false
    if (decimalNumbers[decimalNumbers.length - 1] === '0') return false
  }

  return true
}

const value = computed({
  get: () => {
    const input_number = props.modelValue ? toNumber(props.modelValue) : null
    if (isFocused.value) {
      return unformat_currency(input_number)
    } else {
      return format_currency(input_number)
    }
  },
  set: (value) => {
    if (!canSet(value)) return
    const input_number = unformat_currency(value)
    let input_money = null
    if (!!input_number || input_number === 0) {
      input_money = toMoney(
        input_number || 0,
        props.modelValue?.currency || 'USD',
        props.modelValue?.common_amount,
        props.modelValue?.common_currency,
      )
    }
    emit('update:modelValue', input_money)
  },
})

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)

const onInput = (e) => {
  if (!canSet(e.target.value)) return
  if (!props.onChange) return
  props.onChange()
}

const textSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-sm'
    case 'md':
      return 'text-md'
    case 'lg':
      return 'text-lg'
    default:
      return 'text-sm'
  }
})

const inputTextAlign = computed(() => {
  switch (props.align) {
    case 'left':
      return 'text-left'
    case 'center':
      return 'text-center'
    case 'right':
      return 'text-right'
    default:
      return 'text-right'
  }
})
</script>

<template>
  <fieldset>
    <label :for="id" class="block text-sm font-medium text-gray-700">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div
      :class="[
        'flex w-full items-center gap-1 pl-2',
        error
          ? 'border-red-300 focus:border-red-300 focus:ring-red-200'
          : 'rounded border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50',
        inline ? 'border-none' : 'mt-1 rounded-md border',
        disabled ? 'bg-gray-50 text-gray-500' : '',
        inputClass,
      ]"
    >
      <input
        v-model="value"
        :disabled="disabled"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        type="text"
        @input="onInput"
        ref="inputRef"
        class="w-full rounded border-none px-0 pr-3 focus:border-none focus:outline-none focus:ring-0"
        :class="[textSize, inputTextAlign, disabled ? 'bg-gray-50 text-gray-500' : '']"
      />
    </div>
    <template v-if="error && !inline">
      <p class="mt-1 text-sm text-red-500">{{ error }}</p>
    </template>
    <template v-if="description">
      <p class="mt-1 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
    </template>
  </fieldset>
</template>
