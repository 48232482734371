<script lang="ts" setup>
withDefaults(
  defineProps<{
    color?: string
    size?: string
  }>(),
  {
    color: 'gray',
    size: 'sm',
  },
)

// prettier-ignore
const colors = {
  'gray': 'border border-gray-300 bg-gray-50 text-gray-600',
  'v-blue': 'bg-[#3982AF]/10 text-[rgb(40,96,130)] ring-1 ring-[#3982AF]/40',
  'v-red': 'bg-red-600/90 text-white ring-1 ring-red-600/90',
  'v-yellow': 'bg-yellow-300 ring-yellow-300',
  'v-green': 'bg-green-400/60 ring-1 ring-green-400/70',
  'v-gray': 'bg-gray-400/60 ring-1 ring-gray-400/70 ',
}

// prettier-ignore
const sizes = {
  'sm': 'px-1.5 py-0 text-xs'
}
</script>

<template>
  <span class="inline-block rounded-full" :class="[colors[color], sizes[size]]">
    <slot></slot>
  </span>
</template>
