<script setup lang="ts">
import { useEntityStore } from '@/modules/investing/stores/entity-store'
import { format as formatDateFNS } from 'date-fns'
import { capitalize } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { rails_url } from '@/modules/shared/utils/rails'
import {
  PortfolioSection,
  VSection,
  TheContent,
  VButton,
  VDescriptionList,
  VDropdownImproved,
} from '@/modules/shared/components'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { usePortfolioStore } from '../stores/portfolio-store'
import { useOtherEntityStore } from '../stores/other-entity-store'
import AllEntitiesTable from '../components/all-entities-table.vue'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { format } from '@/modules/shared/utils/v-table'
import DealCard from '@/modules/dashboard/components/deal-card.vue'
import { useDealStore } from '@/modules/dashboard/stores/deal-store'
import { createOptions } from '@/modules/shared/utils/form'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

const { t } = useExtendedI18n()
const route = useRoute()

const cid = computed(() => `individual:${route.params.individual_id}`)
const dealStore = useDealStore()
const otherEntityStore = useOtherEntityStore()
const authStore = useAuthStore()
const investorStore = useInvestingInvestorStore()
const portfolioStore = usePortfolioStore()
const entityStore = useEntityStore()
const workspaceStore = useWorkspaceStore()
const skeleton = ref(true)
const currentIndividual = ref(null)
const deals = computed(() => (skeleton.value ? [{}, {}, {}, {}, {}] : dealStore.deals))
const selectedProfileKeys = ref([])

const profileOptions = computed(() => {
  const options = [
    {
      label: currentIndividual.value?.name,
      value: cid.value,
    },
  ]

  otherEntityStore.other_entities.forEach((other_entity) => {
    options.push({
      label: other_entity.name,
      value: other_entity._cid,
    })
  })

  return options
})
const viewingAs = computed(() => {
  if (selectedProfileKeys.value.length === profileOptions.value.length) {
    return `all ${t('shared.investor', 0)}`
  }

  if (selectedProfileKeys.value.length === 1) {
    return profileOptions.value.find((option) => option.value === selectedProfileKeys.value[0]).label
  }

  if (selectedProfileKeys.value.length > 1 || selectedProfileKeys.value.length < 1) {
    return `${selectedProfileKeys.value.length} ${t('shared.investor', selectedProfileKeys.value.length)}`
  }
})

const investments = computed(() => {
  // if conditions are added because there are investments that do not belong to the individual being viewed, and it belongs to entities (viewable in method filter in portfolio page), so the filter of the individual being viewed should cover these investments
  if (selectedProfileKeys.value.length === profileOptions.value.length) return portfolioStore.investments

  if (selectedProfileKeys.value.includes(cid.value)) {
    const disabled_cids = profileOptions.value
      .filter((option) => !selectedProfileKeys.value.includes(option.value))
      .map((option) => option.value)

    return portfolioStore.investments.filter((investment) => !disabled_cids.includes(investment.investor_cid))
  }
  return portfolioStore.investments.filter((investment) => selectedProfileKeys.value.includes(investment.investor_cid))
})

const is_viewing_own_profile = computed(
  () => route.params.individual_id === authStore.current_user.investor_id?.toString(),
)
const is_entity_link_disabled = computed(() => !authStore.is_site_or_group_admin && !is_viewing_own_profile.value)
const can_view_deals = computed(
  () =>
    is_viewing_own_profile.value &&
    workspaceStore.current.options.can_access_rails &&
    workspaceStore.current.options.is_company_list_enabled,
)

onMounted(async () => {
  await Promise.all([
    workspaceStore.fetchCurrent(),
    portfolioStore.fetchPortfolio({ _cid: `individual:${route.params.individual_id}`, as_of_date: new Date() }),
    otherEntityStore.fetchOtherEntities('individual', route.params.individual_id),
    investorStore.fetchIndividual(route.params.individual_id),
    entityStore.fetchEntities('spv', `individual:${route.params.individual_id}`),
    entityStore.fetchEntities('fund', `individual:${route.params.individual_id}`),
  ])

  if (can_view_deals.value) {
    await dealStore.fetchDeals({ is_featured: true, limit: 3 })
  }

  currentIndividual.value = investorStore.items.get(`individual:${route.params.individual_id}`)
  selectedProfileKeys.value = profileOptions.value?.map((option) => option.value)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />

    <VSection>
      <div class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] px-2 py-3 sm:px-4">
        <div class="flex flex-col-reverse justify-start gap-2 sm:flex-row sm:items-center">
          <VDropdownImproved v-model="selectedProfileKeys" aligned="left" :options="profileOptions" class="z-[1]">
            <VButton size="md">
              <div class="flex items-center space-x-2">
                <div>Filter by {{ viewingAs }}</div>
                <VIcon name="chevron_selector_vertical" />
              </div>
            </VButton>
          </VDropdownImproved>

          <p class="text-sm font-medium text-gray-700">
            Use this filter to view your portfolio as a specific investor or vehicle
          </p>
        </div>
      </div>
    </VSection>

    <VSection label="Featured Deals" v-if="deals.length > 0 && can_view_deals">
      <template #actions>
        <a :href="`${rails_url()}/list/featured`" class="text-sm text-[#3D8A9D] underline">View All</a>
      </template>
      <div class="flex max-w-full gap-3 overflow-x-auto">
        <DealCard v-for="deal in deals" :deal="deal" :skeleton="skeleton" />
      </div>
    </VSection>

    <PortfolioSection :is_portfolio_view_visible="false" :skeleton="skeleton" :investments="investments" />

    <AllEntitiesTable
      :cid="cid"
      :filterKeys="selectedProfileKeys"
      :skeleton="skeleton"
      :funds="entityStore.funds"
      :spvs="entityStore.spvs"
      :other_entities="otherEntityStore.other_entities"
      :is_entity_link_disabled="is_entity_link_disabled"
      :hide_charts="true"
    />
  </TheLayout>
</template>
