import { createService } from '@/modules/shared/utils/service'

export const useFilingLocatorService = createService({
  base_url: `${import.meta.env.VITE_VENTURE360_API_URL}/:slug/tax/filing-locator`,
  // prettier-ignore
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
  endpoints: [
    {
      method: 'GET',
      name: 'list',
      url: 's?year=:year', // looks strange, but pluralizes the base_url
    },
    {
      method: 'GET',
      name: 'retrieve',
      url: '/:id', // looks strange, but pluralizes the base_url
    },
    {
      method: 'PATCH',
      name: 'patch',
      url: '/:id',
    },
    {
      method: 'POST',
      name: 'approve_prepared_return',
      url: '/:id/approve-prepared-return',
    },
    {
      files: true,
      method: 'POST',
      name: 'attach_finalized_return_document',
      url: '/:id/attach-finalized-return-document',
    },
    {
      files: true,
      method: 'POST',
      name: 'attach_investor_return_document',
      url: '/:id/attach-investor-return-document',
    },
    {
      files: true,
      method: 'POST',
      name: 'attach_prepared_return_document',
      url: '/:id/attach-prepared-return-document',
    },
    {
      method: 'POST',
      name: 'reject_prepared_return',
      url: '/:id/reject-prepared-return',
    },
    {
      method: 'POST',
      name: 'remove_finalized_return_document',
      url: '/:id/remove-finalized-return-document',
    },
    {
      method: 'POST',
      name: 'remove_prepared_return_document',
      url: '/:id/remove-prepared-return-document',
    },
    {
      method: 'POST',
      name: 'mark_as_final_return',
      url: '/:id/mark-as-final-return',
    },
    {
      method: 'POST',
      name: 'recall_k1s',
      url: '/:id/recall-k1s',
    },
    {
      method: 'POST',
      name: 'set_return_status',
      url: '/:id/set-return-status',
    },
    {
      method: 'POST',
      name: 'unmark_as_final_return',
      url: '/:id/unmark-as-final-return',
    },
    {
      method: 'POST',
      name: 'set_federal_status',
      url: '/:id/set-federal-status',
    },
    {
      method: 'POST',
      name: 'set_state_status',
      url: '/:id/set-state-status',
    },
    {
      method: 'POST',
      name: 'set_federal_extension_status',
      url: '/:id/set-federal-extension-status',
    },
    {
      method: 'POST',
      name: 'set_state_extension_status',
      url: '/:id/set-state-extension-status',
    },
    {
      method: 'POST',
      name: 'set_final_return',
      url: '/:id/set-final-return',
    },
  ],
})
