<script setup lang="ts">
import { VTextField, VTextArea, VButton, VIcon } from '@/modules/shared/components'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { computed, onMounted, ref, watch } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { capitalize, cloneDeep, differenceWith, isEqual } from 'lodash'
import { useTemplateStore } from '@/modules/signing/stores/template-store'
import VSelect from './v-select.vue'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useRoute, useRouter } from 'vue-router'
import { useEntityStore } from '../stores/entity-store'
import { useDocumentStore } from '../stores/document-store'
import IndexVSection from './index-v-section.vue'
import VDropdownMultiple from './v-dropdown-multiple.vue'

//////////////////////////
///// UTILS
//////////////////////////
const toSnakeCase = (str) => str.replace(' ', '_').toLowerCase()
const combine = (list: any[], n: number = 0, result: any[] = [], current: any[] = []) => {
  if (n === list.length) {
    result.push(current)
  } else {
    list[n].forEach((item: any) => combine(list, n + 1, result, [...current, item]))
  }

  return result
}
const getRoleWithMultipleSigner = (new_signers, old_signers) => {
  let hasAdded = false

  signer_roles.value.forEach((role) => {
    const newRoleSigners = new_signers[role] || []
    const oldRoleSigners = old_signers[role] || []

    // Detect added elements
    const addedSigners = differenceWith(newRoleSigners, oldRoleSigners, isEqual)
    if (addedSigners.length > 0) {
      hasAdded = true
    }
  })
  const role_w_multiple_signer = hasAdded
    ? signer_roles.value.find((role) => old_signers[role]?.length > 1)
    : signer_roles.value.find((role) => new_signers[role]?.length > 1)

  return role_w_multiple_signer
}
const generateOption = (investor, admin = null, type = null) => {
  if (admin) {
    return {
      label: `${investor.name} - ${admin.name}`,
      value: `${investor._cid}_${admin._cid}`,
      type: type || 'Admin',
    }
  } else {
    return {
      label: investor.name,
      value: investor._cid,
      type: type || 'Investor',
    }
  }
}
const generateSectionLabel = (label) => {
  return {
    label: label,
    value: '',
    section_label: true,
  }
}

//////////////////////////
///// MAIN
//////////////////////////
const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  investor_type: 'spv' | 'fund' | 'gp' | 'group'
  investor_id: string | number
}>()

const documentStore = useDocumentStore()
const investorStore = useInvestingInvestorStore()
const templateStore = useTemplateStore()
const entityStore = useEntityStore()

const selected_template = ref(null)
const currentSignerTabIndex = ref(0)
const signers_errors = ref(null)

onMounted(async () => {
  await Promise.all([
    templateStore.fetchTemplates(props.investor_type, props.investor_id),
    investorStore.fetchInvestors(),
  ])
  if (['spv', 'fund', 'gp'].includes(props.investor_type)) {
    await entityStore.fetchEntity({
      id: props.investor_id,
      type: props.investor_type,
    })
  }
  if (route.query.hellosign_template_id) {
    selected_template.value = templateStore.templates.find(
      (template) => template.hellosign_template_id === route.query.hellosign_template_id,
    )
    if (selected_template.value) document_form.value.template_id = selected_template.value.id
  }
  signer_roles.value.forEach((role) => {
    document_form.value.signers[role] = []
  })
})

//////////////////////////
///// COMPUTED
//////////////////////////
const cid = computed(() => `${props.investor_type}:${props.investor_id}`)
const currentEntity = computed(() => entityStore.items.get(cid.value))
const templateOptions = computed(() =>
  markDisabledOptions(createOptions(templateStore.template_items, { label: 'name' }), []),
)
const entity_admin_cids = computed(
  () => currentEntity.value?.managers?.map((manager) => `individual:${manager.id}`) || [],
)
const entity_investor_cids = computed(() => currentEntity.value?.investors?.map((investor) => investor._cid) || [])
const investorOptions = computed(() => {
  const investors = investorStore.investors.filter((investor) => !['spv', 'fund', 'gp'].includes(investor._custom_type))
  const investor_options = []

  if (props.investor_type === 'group') {
    investor_options.push(generateSectionLabel('Individuals'))
    investor_options.push(
      investors
        .filter((investor) => investor._custom_type === 'individual')
        .map((investor) => generateOption(investor))
        .flat()
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
    investor_options.push(generateSectionLabel('Other Entities'))
    investor_options.push(
      investors
        .filter((investor) => investor._custom_type !== 'individual')
        .map((investor) => investor.admins.map((admin) => generateOption(investor, admin)))
        .flat()
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
  } else {
    investor_options.push(
      investors
        .reduce((acc, investor) => {
          if (entity_admin_cids.value.includes(investor._cid)) acc.push(generateOption(investor, null, 'Admin'))
          if (entity_investor_cids.value.includes(investor._cid)) {
            if (investor._custom_type === 'individual') acc.push(generateOption(investor))
            else acc.push(...investor.admins.map((admin) => generateOption(investor, admin)))
          }
          return acc
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
    investor_options.push(generateSectionLabel('All Investors'))
    investor_options.push(
      investors
        .filter(
          (investor) =>
            !entity_investor_cids.value.includes(investor._cid) && !entity_admin_cids.value.includes(investor._cid),
        )
        .reduce((acc, investor) => {
          if (investor._custom_type === 'individual') acc.push(generateOption(investor))
          else acc.push(...investor.admins.map((admin) => generateOption(investor, admin)))
          return acc
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
  }

  return investor_options.flat()
})
const signer_roles = computed(() => selected_template.value?.signing_roles?.map((role) => toSnakeCase(role)) || [])
const signer_tabs = computed(() => signer_roles.value.map((role) => capitalize(`${role} role`)))

//////////////////////////
///// FORM
//////////////////////////
const initalState = {
  template_id: null,
  signers: {},
  title: null,
  message: null,
}
const rules = {
  template_id: { required },
}
const document_form = ref({ ...initalState })
const v$ = useVuelidate(rules, document_form, { $lazy: true })
const generateSigner = (investor_cid, investor_admin_cid, signer_role) => {
  // get investor and its admin (admin is for entities only)
  const investor = investorStore.investorItems.get(investor_cid)
  const investor_admin = investor_admin_cid ? investor.admins.find((admin) => admin._cid === investor_admin_cid) : null
  const role = selected_template.value?.signing_roles.find((role) => toSnakeCase(role) === signer_role)

  if (entity_admin_cids.value.includes(investor_cid)) {
    return {
      investor_id: null,
      investor_type: null,
      signer_id: investor.id,
      name: investor.name.trim(),
      email_address: investor.email,
      type: 'admin',
      cc: [],
      role: role,
    }
  }

  if (investor_admin) {
    return {
      investor_id: investor.id,
      investor_type: 'InvestorSet',
      signer_id: investor_admin.id,
      name: `${investor.name.trim()} - ${investor_admin.name.trim()}`,
      email_address: investor_admin.email,
      type: 'admin',
      cc: [],
      role: role,
    }
  } else {
    return {
      investor_id: investor.id,
      investor_type: 'Investor',
      signer_id: investor.id,
      name: investor.name.trim(),
      email_address: investor.email,
      type: 'investor',
      cc: [],
      role: role,
    }
  }
}
const saveDocument = async () => {
  // validate basic info of form
  let is_valid = await v$.value.$validate()
  if (!is_valid) return
  // validate signers
  for (const role of signer_roles.value) {
    if (document_form.value.signers[role].length < 1) {
      signers_errors.value = `Please select at least one investor for each role to continue.`
      return
    }
  }

  // prettier-ignore
  if (!window.confirm(
      'Are you sure? This will send legally binding signature requests to every signer selected. Reminder emails will be sent every 3 days for the next 120 days or until signed.',
  ))
    return

  // transform data
  const signers = signer_roles.value.map((role) => {
    return document_form.value.signers[role].map((signer) => {
      const [investor_cid, investor_admin_cid] = signer.split('_')
      return generateSigner(investor_cid, investor_admin_cid, role)
    })
  })
  const signer_list = combine(signers)
  const payload = {
    batchable_id: props.investor_id,
    batchable_type: props.investor_type === 'group' ? 'Group' : 'InvestorSet',
    email_message: document_form.value.message,
    title: document_form.value.title,
    template_id: document_form.value.template_id,
    signer_list: signer_list,
    signers: signers.flat(),
  }

  await documentStore.addDocument(payload)

  if (props.investor_type === 'group') {
    router.push({ name: 'investing.documents' })
  } else {
    router.push({ name: 'investing.entity.documents' })
  }
}

//////////////////////////
///// WATCH
//////////////////////////
watch(
  () => cloneDeep(document_form.value.signers), // Create a deep copy to avoid reference issues
  (new_signers, old_signers) => {
    if (isEqual(new_signers, old_signers)) return
    if (Object.keys(old_signers).length === 0) return

    const role_w_multiple_signer = getRoleWithMultipleSigner(new_signers, old_signers)

    if (role_w_multiple_signer) {
      signer_roles.value.forEach((role) => {
        if (document_form.value.signers[role].length > 1 && role !== role_w_multiple_signer) {
          document_form.value.signers[role] = new_signers[role].slice(0, -1)
          signers_errors.value = `${capitalize(
            role_w_multiple_signer,
          )} role already has multiple signers. You can't add more for ${capitalize(role)} role.`
        }
      })
    } else {
      signers_errors.value = null
    }
  },
  { deep: true },
)
</script>

<template>
  <IndexVSection class="flex gap-14 pb-10" index="1">
    <div class="w-1/2 pr-5">
      <h3 class="text-lg font-[500] text-gray-900 lg:text-lg">Select template</h3>
      <p class="mt-3 text-sm">Choose a template you've already built, or build a new one.</p>
    </div>
    <div class="w-1/2">
      <div class="flex items-center gap-3">
        <VSelect
          v-model="document_form.template_id"
          :disabled="false"
          :options="templateOptions"
          property="template_id"
          :v$="v$"
          size="sm"
          class="flex-grow"
        />
        <RouterLink
          :to="{
            name: 'signing-entity-build-template',
            params: { templateable_type: props.investor_type, templateable_id: props.investor_id },
          }"
          class="hyperlink text-sm"
          >Build new template</RouterLink
        >
      </div>
    </div>
  </IndexVSection>
  <IndexVSection class="flex gap-14 pb-10" index="2">
    <div class="w-1/2 pr-5">
      <h3 class="text-lg font-[500] text-gray-900 lg:text-lg">Add signers</h3>
      <p class="mt-3 text-sm">
        Each role requires at least one signer to continue. Add multiple signers to a role to batch initiate multiple
        signature requests.
      </p>
    </div>
    <div class="w-1/2 space-y-5" v-if="selected_template">
      <div class="mb-3 border-[1px] bg-red-100 p-2 text-sm text-red-700 ring-red-200/75" v-if="signers_errors">
        {{ signers_errors }}
      </div>
      <div class="border-b border-gray-200 dark:border-zinc-800">
        <nav class="relative -mb-px flex space-x-3 sm:space-x-6" aria-label="Tabs">
          <div
            v-for="(tab, index) in signer_tabs"
            :key="tab"
            @click.prevent="currentSignerTabIndex = index"
            class="sm:text-md relative flex cursor-pointer overflow-x-clip whitespace-nowrap border-b py-1.5 font-medium leading-7"
            :class="[
              index === currentSignerTabIndex
                ? 'border-current font-semibold text-[#3b88af]'
                : 'border-transparent font-medium text-gray-700 hover:border-gray-300 dark:text-zinc-300',
              'text-sm',
            ]"
          >
            <div class="flex items-center gap-3">
              <div>{{ tab }}</div>
              <div
                class="h-5 w-5 rounded pt-[3px] text-center text-xs font-normal text-black"
                :class="[index === currentSignerTabIndex ? 'bg-sky-100' : 'bg-gray-100']"
              >
                {{ document_form.signers[signer_roles[index]].length }}
              </div>
            </div>
            <div
              v-if="index === currentSignerTabIndex"
              class="pointer-events-none absolute -left-4 top-[41px] flex h-8 rotate-180 items-end overflow-hidden"
            >
              <div class="-mb-px flex h-[2px] w-24 rotate-180">
                <div
                  class="w-full flex-none blur-sm [background-image:linear-gradient(-90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"
                ></div>
                <div
                  class="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"
                ></div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <VDropdownMultiple
        aligned="left"
        class="z-auto"
        :options="investorOptions"
        v-model="document_form.signers[signer_roles[currentSignerTabIndex]]"
        :show-selected-list="true"
        v-for="role in signer_roles"
        v-show="signer_roles[currentSignerTabIndex] === role"
      >
        <VButton class="w-fit">
          <div class="flex items-center justify-between gap-3">
            <div>Add signer</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownMultiple>
    </div>
  </IndexVSection>
  <IndexVSection class="flex gap-14 pb-10" index="3">
    <div class="w-1/2 pr-5">
      <h3 class="text-lg font-[500] text-gray-900 lg:text-lg">Write a message</h3>
      <p class="mt-3 text-sm">
        Rename the document and email message to accompany the signing request. Note this is optional and if left blank,
        the document name and email message will match the template.
      </p>
    </div>
    <div class="w-1/2 space-y-5">
      <VTextField
        v-model="document_form.title"
        :label="capitalize(t('shared.document title'))"
        property="title"
        :placeholder="selected_template?.title"
        :v$="v$"
      />
      <VTextArea
        :label="capitalize(t('shared.message'))"
        v-model="document_form.message"
        :v$="v$"
        property="message"
        :placeholder="selected_template?.message"
      />
    </div>
  </IndexVSection>
  <IndexVSection class="flex items-center gap-10 pb-10" index="4">
    <div class="w-1/2 pr-5">
      <h3 class="text-lg font-[500] text-gray-900 lg:text-lg">Finalize</h3>
      <p class="mt-3 text-sm">
        Each signer will recieve an email requesting their signature.
        <span class="underline">Reminder emails will be sent every 3 days for the next 120 days or until signed.</span>
      </p>
    </div>
    <div class="w-1/2">
      <VButton variant="v-blue" size="lg" :click="saveDocument"> Confirm and request signatures </VButton>
    </div>
  </IndexVSection>
</template>
